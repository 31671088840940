$('.tabs-content').ready(function() {
    handleAnotherContainerLink();
    handleVehicleBinIcon();
});

const handleAnotherContainerLink = function () {
    const vinLink = $('.tabs-content').find('.filter-header .vin-link a');
    const filtersLink =  $('.tabs-content').find('.search-header .filters-link a');
    const vinContainer = $('.tabs-content').find('#search-by-vin-container');
    const filtersContainer = $('.tabs-content').find('#filter-container');

    vinLink.click(function (e) {
        e.preventDefault();

        filtersContainer.hide();
        vinContainer.show();
    });

    filtersLink.click(function (e) {
        e.preventDefault();

        vinContainer.hide();
        filtersContainer.show();
    });
}

const handleVehicleBinIcon = function () {
    $('.tabs-content').find('table a.bin-icon').each(function (i, binIcon) {
        $(binIcon).on('click', function (e) {
            e.stopPropagation();
            e.preventDefault();
            const that = $(this);
            const deleteModal = $('.ntn-modal--delete');
            deleteModal.parent().css({display: "flex"});
            deleteModal.find('.ntn-modal-header--title').text(that.data('modal-title'));
            deleteModal.find('.ntn-modal-body > b').text(that.data('modal-body'));
            deleteModal.find('#delete-btn').attr('data-href', this.href);
        });
    });
}
