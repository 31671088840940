// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'jquery'
import "jquery-ui"
import '@popperjs/core'
require("bootstrap");
import 'select2'
import "inputmask";
import "jquery.alphanum";
require("packs/jquery.ba-floatingscrollbar.min.js")

import '../modules/select'
import '../modules/dropdown'
import '../modules/alert'
import '../modules/modal'
import '../modules/stepper'
import '../modules/toggle'
import '../modules/tabs'
import '../modules/password_inputs'
import '../modules/pagination'
import '../modules/numeric_fields'
import '../modules/number_fields'
import '../modules/notifications'

import '../pages/profile'
import '../pages/sign_up'
import '../pages/company'
import '../pages/vehicles_by_vin'
import '../pages/manage_password_page'
import '../pages/vehicles_filters'
import '../pages/vehicle_show'
import '../pages/vehicle_prices_modal'
import '../pages/vehicle_for_trade_modal'
import '../pages/vehicle_package_modal'
import '../pages/vehicle_package_prices_modal'
import '../pages/vehicle_change_from_for_trade_status_modal'
import '../pages/vehicle_change_from_package_status_modal'
import '../pages/vehicle_edit'
import '../pages/new_mapping'
import '../pages/temp_vehicles'
import '../pages/inventory_tab'
import '../pages/requests'
import '../pages/request_new'
import '../pages/add_for_trade_vehicle_modal'

Rails.start()
ActiveStorage.start()
