$('.vehicle-header').ready(function () {
    handleShowMoreOptions();
    handleCopyVinIcon();
    handleContactBlock();
    handleForTradeLink();
    handleStatusBtn();
    handleEditForTradeLink();
    handleEditPackageLink();
    handlePackageItemClick();
    handleForTradeVehicleExpand();
});

const handleShowMoreOptions = function () {
    const link = $('#show-more-options');
    const preview = link.parent().find('.options-preview');
    const full = link.parent().find('.options-full');
    const less = link.find('.less');
    const more = link.find('.more');
    link.click(function (){
        full.toggle();
        preview.toggle();
        less.toggle();
        more.toggle();
    });
}

const handleCopyVinIcon = function () {
    const icon = $('#copy-vin');
    const vin = icon.parent().find('span');
    icon.click(function () {
        let $temp = $("<input>");
        vin.append($temp);
        $temp.val(vin.text()).select();
        document.execCommand("copy");
        $temp.remove();
    })
}

const handleContactBlock = function () {
    const phone = $('#company-phone');
    const email = $('#company-email');
    const address = $('#company-address');
    phone.click(function (){
        window.location.href = `tel:${phone.text()}`;
    });
    email.click(function (){
        window.location.href = `mailto:${email.text()}`;
    })
    address.click(function (){
        window.open(`http://maps.google.com/?q=${address.text()}`);
    })
}

const handleForTradeLink = function () {
    const forTradeLink = $('#for-trade-link');
    const forTradeContainer = $('.for-trade-container');
    const packageLink = $('#package-link');
    const packageContainer = $('.package-container');
    forTradeLink.click(function () {
        $('html, body').animate({
            scrollTop: forTradeContainer.offset().top - 60
        }, 500);
    });
    packageLink.click(function () {
        $('html, body').animate({
            scrollTop: packageContainer.offset().top - 60
        }, 500);
    });
}

const handleStatusBtn = function () {
    const btn = $('.status-btn');
    const dropdown = btn.find('.dropdown');
    const priceModal = $('.ntn-modal--vehicle-prices').parent();
    const forTradeModal = $('.ntn-modal--vehicle-for-trade').parent();
    const packageModal = $('.ntn-modal--vehicle-package').parent();
    const fromForTradeModal = $('.ntn-modal--change-from-for-trade-status').parent();
    const fromPackageModal = $('.ntn-modal--change-from-package-status').parent();

    btn.click(function () {
        dropdown.toggle();
    });

    $(window).click(function (e) {
        if (e.target !== btn[0] ) {
            dropdown.hide();
        }
    });


    dropdown.find('.item').click(function () {
        const that = $(this);
        if (that.hasClass('active')) { return; }

        const allPrices = btn.data('all-prices');
        const currentStatus = btn.data('current-status');
        const dontShowModalFromForTrade = btn.data('dont-show-modal-from-for-trade');
        if (typeof allPrices === 'undefined' && that.data('status') !== 'In stock') {
            priceModal.find('.ntn-modal-header--title').text(`Change status to "${that.data('status')}"`);
            if (that.data('status') === 'Package') {
                priceModal.find('.ntn-modal-body .prices.col-5 .description span').text('"Package"');
            } else if (that.data('status') === 'For trade') {
                priceModal.find('.ntn-modal-body .prices.col-5 .description span').text('"For trade"');
            }
            priceModal.attr({ 'data-next-btn': that.data('status') });
            priceModal.css({ display: "flex" });
            return;
        }

        if(that.data('status') === 'For trade') {
            if (currentStatus === 'package') {
                fromPackageModal.attr({ 'data-status-to': 'for_trade' });
                fromPackageModal.find('.ntn-modal-header--title span').text('For trade');
                fromPackageModal.find('#apply-btn').text('Continue');
                fromPackageModal.find('span.aim').text('second one.');
                fromPackageModal.css({ display: 'flex' });
            } else if (currentStatus === 'in_stock') {
                forTradeModal.css({ display: "flex" });
            }

        } else if(that.data('status') === 'Package') {
            if (currentStatus === 'for_trade') {
                fromForTradeModal.attr({ 'data-status-to': 'package' });
                if (!dontShowModalFromForTrade) {
                    fromForTradeModal.find('.ntn-modal-header--title span').text('Package');
                    fromForTradeModal.css({ display: 'flex' });
                    return;
                }
                fromForTradeModal.find('#ok-btn').trigger('click');
            } else if (currentStatus === 'in_stock') {
                packageModal.css({ display: "flex" });
            }

        } else if (that.data('status') === 'In stock') {
            fromForTradeModal.attr({ 'data-status-to': 'in_stock' });
            if (currentStatus === 'for_trade') {
                if (!dontShowModalFromForTrade) {
                    fromForTradeModal.find('.ntn-modal-header--title span').text('In stock');
                    fromForTradeModal.css({ display: 'flex' });
                    return;
                }
                fromForTradeModal.find('#ok-btn').trigger('click');
            } else if (currentStatus === 'package') {
                fromPackageModal.attr({ 'data-status-to': 'in_stock' });
                fromPackageModal.find('.ntn-modal-header--title span').text('In stock');
                fromPackageModal.find('span.aim').text('both.');
                fromPackageModal.css({ display: 'flex' });
            }
        }
    });
}

const handleEditForTradeLink = function () {
    const link = $('.for-trade-container').find('a');
    const forTradeModal = $('.ntn-modal--vehicle-for-trade').parent();

    link.click(function (e) {
        e.preventDefault();

        forTradeModal.attr({ 'data-back-btn': false });
        forTradeModal.css({ display: "flex" });
    });
}

const handleEditPackageLink = function () {
    const link = $('.package-container').find('a');
    const packageModal = $('.ntn-modal--vehicle-package').parent();
    link.click(function (e) {
        e.preventDefault();

        packageModal.attr({ 'data-back-btn': false });
        packageModal.find('.ntn-modal-header--title').text('Edit package');
        packageModal.find('.ntn-modal-body .description').hide();
        packageModal.find('.ntn-modal-body .title').first().text('Select vehicle');

        packageModal.css({ display: "flex" });
    });
}

const handlePackageItemClick = function () {
    $('.vehicle-details .package-container').find('.item').each(function (i, item){
        $(item).click(function () {
            window.location = $(item).data("link");
        });
    });
}

const handleForTradeVehicleExpand = function () {
    $('.for-trade-container').find('.actions[data-disabled-expand="false"]').each(function (i, item) {
        item = $(item);
        item.click(function () {
            item.find('i.ntn-icons.arrow-down').toggle();
            item.find('i.ntn-icons.arrow-up').toggle();
            item.parents('.item').find('.matched-companies').slideToggle();
        });
        item.parents('.item').find('.matched-companies .matched-item').each(function (i, item) {
            $(item).click(function () {
                window.location = $(item).data("href");
            });
        });
    });
}
