$(document).ready(function() {
    $('.preview-password').each(function () {
        const that = $(this);
        const eyeIconStructure = '<div class="icon-right"><i class="ntn-icons eye" style="vertical-align: bottom"></i></div>';
        that.append(eyeIconStructure);

        that.find('.icon-right i').click(function (){
            if(that.find('input')[0].type === 'password') {
                that.find('input')[0].type = 'text';
            } else {
                that.find('input')[0].type = 'password';
            }
            $(this).toggleClass('eye');
            $(this).toggleClass('no-preview');
        });
    });

    $('.validate_password').each(function () {
        const that = $(this);
        const thatInput = that.find('input:password');
        const errorContent = thatInput.next('.error-content');
        thatInput.on('keyup', function () {
            if (thatInput.val().length < 8) {
                errorContent.text('It is too short, should be 8 characters or more');
                that.addClass('error');
                that.removeClass('success-pass');
            } else {
                errorContent.text('');
                that.removeClass('error');
                that.addClass('success-pass');
            }
            if (that.data('confirmation-field')) {
                let repeatPasswordField = that.parent().find('.validate_confirm_password').find('input:password');
                if (repeatPasswordField.val().length > 0)
                    repeatPasswordField.trigger('keyup');
            }
        });
    });

    $('.validate_confirm_password').each(function () {
        const that = $(this);
        const thatInput = that.find('input:password');
        const errorContent = thatInput.next('.error-content');
        const passwordField = that.parent().find(".validate_password[data-confirmation-field='true']").find('input:password');
        thatInput.on('keyup', function () {
            if (thatInput.val() !== passwordField.val()) {
                errorContent.text("Doesn't match");
                that.addClass('error');
                that.removeClass('success-pass');
            } else {
                errorContent.text('');
                that.removeClass('error');
                if (passwordField.parents('.input-group-large').hasClass('success-pass')) {
                    that.addClass('success-pass');
                }
            }
        });
    });

    const saveBtn = $('.save-btn');
    let passFields = $('input:password');
    passFields.each(function (){
        const that = $(this);
        that.on('keyup', function () {
            let passInputGroups = []
            passFields.each(() => { passInputGroups.push($(this).parents('.input-group-large').hasClass('success-pass')) })
            toggleSaveBtn(passInputGroups.every(function (e) { return e === true }), saveBtn)
        });
    });

    const toggleSaveBtn = function (success, btn) {
        if (success === true) {
            btn.removeAttr('disabled');
        } else {
            btn.attr('disabled', 'true');
        }
    }
});
