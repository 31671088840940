import $ from 'jquery';
import {validateNumeric} from "../modules/numeric_fields";
import {min} from "@popperjs/core/lib/utils/math";

$('.filter-header').ready(function () {
    if ($('.filter-header').length > 0) {
        const yearSelect = $('#filter_year');
        const makeSelect = $('#filter_make');
        const modelSelect = $('#filter_model');
        const trimSelect = $('#filter_trim');
        const submitBtn = $('.filter-header').find('input:submit');
        const clearAllBtn = $('.btns-container').find('p.link:contains("Clear all")');
        const popularParamsBtn = $('.btns-container').find('p.link:contains("Popular parameters")');
        const fields = [yearSelect, makeSelect, modelSelect, trimSelect];
        const popularFiltersContainer = $('.popular-filters-container');

        initDynamicalFilters(fields, clearAllBtn, popularParamsBtn);
        initPopularFilters(popularFiltersContainer, fields, submitBtn);
        handleSubmitBtn(submitBtn, fields);
        handleClearAllBtn(clearAllBtn, fields);
        handlePopularParamsBtn(popularParamsBtn, fields, popularFiltersContainer, submitBtn);

        $(fields).each(function (i, field) {
            field.on('select2:select', function () {
                initPopularFilters(popularFiltersContainer, fields, submitBtn);
                handleClearAllBtn(clearAllBtn, fields);
                handlePopularParamsBtn(popularParamsBtn, fields, popularFiltersContainer, submitBtn);
                onChangeSelectsCallback(fields, field);
                handleSubmitBtn(submitBtn, fields);
            });
        });
    }
});

const initDynamicalFilters = function (fields, clearAllBtn, popularParamsBtn) {
    $.each(fields, function (index, field) {
        if (field.data('params')) {
            field.find('option:eq(0)').prop('selected', true);
            clearAllBtn.removeClass('disabled');
            clearAllBtn.unbind().on('click', function () {
                window.location.href = window.location.href.split("?")[0];
            });

            popularParamsBtn.removeClass('disabled');
            popularParamsBtn.unbind().on('click', function () {
                $('.popular-filters-container').toggle();
            });
        } else {
            field.val(null).trigger('change');
        }
    });
}

const handleClearAllBtn = function (btn, fields) {
    if (fields.some((elem) => { return elem.val() !== null} )) {
        btn.removeClass('disabled');
        btn.on('click', function () {
            if (btn.parents('.tabs-content').length === 0) {
                window.location.href = window.location.href.split("?")[0];
            } else {
                window.location.href = `${window.location.href.split("?")[0]}?inventory_tab=true&filter_tab=true`;
            }
        });
    } else {
        btn.addClass('disabled');
        btn.off('click');
    }
}

const handlePopularParamsBtn = function (btn, fields, popularFiltersContainer, submitBtn) {
    if (fields.some((elem) => { return elem.val() !== null} )) {
        btn.removeClass('disabled');
        btn.attr('role', 'none');
        initPopularFilters(popularFiltersContainer, fields, submitBtn);
        btn.unbind().on('click', function () {
            $('.popular-filters-container').toggle();
        });
    } else {
        btn.addClass('disabled');
        btn.attr('role', 'tooltip');
        btn.off('click');
    }
}

const onChangeSelectsCallback = function (fields, field) {
    let otherFields = fields.filter((elem) => { return elem.get(0) !== field.get(0) });
    let notEmptyFields = fields.filter((elem) => { return elem.val() !== null });
    let params = notEmptyFields.map((elem) => { return { field: elem.data('field'), value: elem.select2('data')[0].text } });
    $.ajax({
        type: "GET",
        url: window.location.pathname + '/fetch_dynamical_filters',
        dataType: 'json',
        contentType: 'application/json',
        data: {
            'dynamical_filters': JSON.stringify(params)
        }
    }).done(function (json) {
        if (otherFields.filter((elem) => { return elem.val() !== null }).length === 3) { otherFields = fields }
        $(otherFields).each(function (index, otherField) {
            let currentVal = otherField.val();
            otherField.empty();
            let otherFieldName = otherField.data('field');
            $(json[otherFieldName]).each(function (i, option) {
                let newOption = new Option(option, option, false, false);
                otherField.append(newOption);
            });
            if (currentVal === null) { otherField.val(null); }
            otherField.trigger('change');
        });
    });
}

const handleSubmitBtn = function (btn, fields) {
    if (fields.some((elem) => { return elem.val() !== null} )) {
        $.ajax({
            type: "GET",
            url: window.location.pathname + '/filter_count',
            dataType: 'json',
            contentType: 'application/json',
            data: createFilterParams()
        }).done(function (json) {
            btn.val(`Show ${json} results`);
            btn.removeAttr('disabled');
            btn.parent().attr('role', 'none');
            $('.item.horizontal').find('.counter').each((i, elem) => { elem.innerText = json} );
        });
    } else {
        btn.attr('disabled', true);
        btn.parent().attr('role', 'tooltip');
        btn.val('Show results');
    }
}

const createFilterParams = function (with_false_values = false) {
    let values = {};
    $.each($('#filter-form').serializeArray(), function(i, field) {
        let condition
        if (with_false_values === false) {
            condition = field.value !== '' && field.value !== 'false';
        } else {
            condition = field.value !== '';
        }
        if (condition) {
            values[field.name] = field.value;
        }
    });
    return values;
}

const initPopularFilters = function (container, fields, submitBtn) {
    if (fields.some((elem) => { return elem.val() !== null} )) {
        $.ajax({
            type: "GET",
            url: window.location.pathname + '/fetch_popular_filters',
            dataType: 'json',
            contentType: 'application/json',
            data: createFilterParams()
        }).done(function (json) {
            $.each(json['toggles'], function (key, array) {
                clearTogglesContainer(container, key);
                $.each(array, function (i, value) {
                    let params = container.find(`#${key}`).data('params');
                    let checked = false;
                    if (params !== undefined) {
                        checked = params[value];
                    }

                    let result =
                        $(`<div class="item">
                            <div class="toggle-container">
                                <div class="toggle">
                                    <input name="filter[${key}][${value}]" type="hidden" value="false">
                                    <input name="filter[${key}][${value}]" type="checkbox" value="true" id="filter_${key}_${value}">
                                    <span class="slider"></span>
                                </div>
                                <label for="filter_${key}_${value}">${value}</label>
                            </div>
                            <div class="counter"></div>
                        </div>`);
                    if (checked === 'true') {
                        result.find('input:checkbox').attr('checked', true);
                    } else {
                        result.find('input:checkbox').removeAttr('checked');
                    }
                    container.find(`#${key}`).append(result);
                });
            });

            $.each(json['ranges'], function (key, values) {
                const sliderDiv = $(`#${key}-range`);
                sliderDiv.empty();
                const params = sliderDiv.data('params');
                let minValue = params[0] || values['min'];
                let maxValue = params[1] || values['max'];

                const minInput = sliderDiv.parent().find('.input-group-small.first').find('input');
                const maxInput = sliderDiv.parent().find('.input-group-small.second').find('input');
                maxInput.val(maxValue);
                minInput.val(minValue);
                validateNumeric([maxInput, minInput], values['min'],  values['max']);

                sliderDiv.slider({
                    range: true,
                    min: values['min'],
                    max: values['max'],
                    values: [minValue, maxValue],
                    classes: {
                        "ui-slider": "ntn-slider",
                        "ui-slider-handle": "ntn-slider-handle",
                        "ui-slider-range": "ntn-slider-range"
                    },
                    slide: function(event, ui) {
                        let sliderValues = ui.values;
                        minInput.val(sliderValues[0]);
                        maxInput.val(sliderValues[1]);
                        $(ui.handle).attr('aria-label', sliderValues[$(ui.handle).data('index')]);
                    },
                    start: function (event, ui) {
                        $(ui.handle).addClass('pressed');
                    },
                    stop: function (event, ui) {
                        $(ui.handle).removeClass('pressed');
                        minInput.trigger("change");
                        maxInput.trigger("change");
                    }
                });

                if ( values['min'] ===  values['max']) {
                    sliderDiv.slider("option", "disabled", true);
                    sliderDiv.parent().addClass('disabled');
                    minInput.prop('disabled', true);
                    maxInput.prop('disabled', true);
                } else {
                    sliderDiv.slider("option", "disabled", false);
                    sliderDiv.parent().removeClass('disabled');
                    minInput.removeProp('disabled');
                    maxInput.removeProp('disabled');
                }

                let sliderValues = $.map(sliderDiv.slider('values'), (val) => { return new Intl.NumberFormat().format(val); });
                sliderDiv.find('span.ntn-slider-handle').first()
                    .attr({'aria-label': sliderValues[0], role: 'tooltip', 'data-microtip-position': 'top', 'data-index': 0});
                sliderDiv.find('span.ntn-slider-handle').last()
                    .attr({'aria-label': sliderValues[1], role: 'tooltip', 'data-microtip-position': 'top', 'data-index': 1});

                minInput.focusout(function () {
                    const that = $(this);
                    const inputValue = that.val();
                    const slider = that.parents('.item').find('.slider-container');
                    let sliderValues = slider.slider('values');
                    slider.slider('values', [inputValue, sliderValues[1]]);
                });
                maxInput.focusout(function () {
                    const that = $(this);
                    const inputValue = that.val();
                    const slider = that.parents('.item').find('.slider-container');
                    let sliderValues = slider.slider('values');
                    slider.slider('values', [sliderValues[0], inputValue]);
                });
            });

            $.each(json['sliders'], function (key, values) {
                const sliderDiv = $(`#${key}-slider`);
                sliderDiv.empty();
                const params = sliderDiv.data('params');
                let currentValue = params || values['max'];
                if(params === 0) { currentValue = 0 }
                const currentInput = sliderDiv.parent().find('.input-group-small').find('input');
                currentInput.val(currentValue);
                validateNumeric([currentInput], values['min'], values['max']);

                sliderDiv.slider({
                    range: 'min',
                    min: values['min'],
                    max: values['max'],
                    value: currentValue,
                    classes: {
                        "ui-slider": "ntn-slider",
                        "ui-slider-handle": "ntn-slider-handle",
                        "ui-slider-range": "ntn-slider-range"
                    },
                    slide: function(event, ui) {
                        let sliderValue = ui.value;
                        currentInput.val(sliderValue);
                        $(ui.handle).attr('aria-label', sliderValue);
                    },
                    start: function (event, ui) {
                        $(ui.handle).addClass('pressed');
                    },
                    stop: function (event, ui) {
                        $(ui.handle).removeClass('pressed');
                        currentInput.trigger("change");
                    }
                });

                if (values['min'] === values['max']) {
                    sliderDiv.slider("option", "disabled", true);
                    sliderDiv.parent().addClass('disabled');
                    currentInput.prop('disabled', true);
                } else {
                    sliderDiv.slider("option", "disabled", false);
                    sliderDiv.parent().removeClass('disabled');
                    currentInput.removeProp('disabled');
                }

                let sliderValue = new Intl.NumberFormat().format(sliderDiv.slider('value'));
                sliderDiv.find('span.ntn-slider-handle')
                    .attr({'aria-label': sliderValue, role: 'tooltip', 'data-microtip-position': 'top' });

                currentInput.focusout(function () {
                    const that = $(this);
                    const inputValue = that.val();
                    sliderDiv.slider('value', inputValue);
                });
            });

            $('.popular-filters-container input').each(function (i, input) {
                $(input).on('change', function (){
                    handleSubmitBtn(submitBtn, fields);
                    handleItemCounter();
                    handleFiltersCounter();
                });
            });
            handleSubmitBtn(submitBtn, fields);
            handleItemCounter();
            handleFiltersCounter();
        });
    }
}

const handleItemCounter = function () {
    let params = createFilterParams(true);
    $.ajax({
        type: "GET",
        url: window.location.pathname + '/popular_filter_count',
        dataType: 'json',
        contentType: 'application/json',
        data: params
    }).done(function (json) {
        const popularFiltersContainer = $('.popular-filters-container');
        $.each(json, function (category, values) {
            let categoryContainer = popularFiltersContainer.find(`#${category}`);
            $.each(values, function (key, value) {
                let checkbox = categoryContainer.find(`input[id='filter_${category}_${key}']`);
                if (value === 0) {
                    checkbox.parents('.toggle-container').addClass('disabled');
                    checkbox.parents('.item').find('.counter').text('');
                    checkbox.parents('.toggle-container').find('label').removeAttr('for');
                } else {
                    checkbox.parents('.item').find('.counter').text(value);
                    checkbox.parents('.toggle-container').removeClass('disabled');
                    checkbox.parents('.toggle-container').find('label').attr({ 'for': $(checkbox).attr('id') });
                }
            });
        });
    });
}

const handleFiltersCounter = function () {
    const filtersCounter = $('.btns-container').find('p.link:contains("Popular parameters") span');
    let counter = 0;

    $('.popular-filters-container').find('input:checkbox').each(function (i, input) {
        if ($(input).prop('checked') === true) {
            counter++;
        }
    });

    $.each([$('#msrp-range'), $('#price-range')], function (i, slider) {
        let min = slider.slider('option', 'min');
        let max = slider.slider('option', 'max');
        if (slider.slider('values')[0] !== min || slider.slider('values')[1] !== max) {
            counter++
        }
    });

    let max = $('#distance-slider').slider('option', 'max');
    if ($('#distance-slider').slider('value') !== max) {
        counter++
    }

    if (counter > 0) {
        filtersCounter[0].innerText = counter;
        filtersCounter.show();
    } else {
        filtersCounter.hide();
    }
}

const clearTogglesContainer = function (container, key) {
    const column = container.find(`#${key}`);
    const header = column.find('.title');
    column.empty();
    column.append(header);
}
