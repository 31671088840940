$(document).ready(function() {
    const notifications = $('.notifications');
    if (notifications.length > 0) {
        handleClick();
        const notificationsContainer = $('.notifications-container');
        const navLink = $('.notifications').find('a.nav-link');

        $.ajax({
            type: "GET",
            url: '/notifications',
            dataType: 'json',
            complete: function (data) {
                $.map(data.responseJSON.notifications, function (notification) {
                    let iconClass = notification.notifiable === 'Request' ? 'ntn-icons approve' : 'ntn-icons taxi';
                    let item = `
                        <a class="notification" href="/notifications/${notification.id}">
                          <div class="icon">
                            <i class="${iconClass}"></i>
                          </div>
                          <div class="body">
                            <div class="title">${notification.title}</div>
                            <div class="description">${notification.description}</div>
                          </div>
                       </a>
                    `;
                    notificationsContainer.append(item);
                });
                if (data.responseJSON.unread_count > 0) {
                    let counter = `<span class="counter">${data.responseJSON.unread_count}</span>`;
                    navLink.append(counter);
                    let actualCounter = navLink.find('span.counter');
                    actualCounter.css({height: actualCounter.width() + 'px'});
                }
            },
            error: function (data) {
                console.log(data)
            }
        });

        $(window).click(function (e) {
            let condition1 = (!navLink.is(e.target) && navLink.has(e.target).length === 0)
            let condition2 = !notificationsContainer.is(e.target) && notificationsContainer.has(e.target).length === 0
            if (condition1 && condition2) {
                notificationsContainer.hide();
            }
        });
    }
});

const handleClick = function () {
    const navLink = $('.notifications').find('a.nav-link');
    const notificationsContainer = $('.notifications-container');
    navLink.on('click', function () {
        if (notificationsContainer.children().length > 0) {
            notificationsContainer.toggle();
        }
    });
}