import $ from "jquery";

$(document).ready(function() {
    $(this).find('select').each(function() {
        let inputGroupSelect = $(this).parents('.input-group-select');
        if (inputGroupSelect.length === 0) return;

        inputGroupSelect.append('<div class="dropdown-parent"></div>');
        let dropdownParent = $(this).parents('.input-group-select').find('.dropdown-parent');
        let that = $(this);

        that.select2({
            selectionCssClass: 'ntn-selection',
            dropdownCssClass: 'ntn-dropdown',
            width: 'resolve',
            dropdownParent: dropdownParent
        }).on('select2:open', function() {
            let parentPosition = inputGroupSelect.offset();
            let dropdown = dropdownParent.find('.ntn-dropdown');
            let parentHeight = inputGroupSelect.outerHeight(true);
            dropdownParent.width($(this).parents('.input-group-select').outerWidth());

            if (inputGroupSelect.hasClass('horizontal')){
                inputGroupSelect.parent().toggleClass('focused');
                inputGroupSelect.addClass('focused');
            }
            inputGroupSelect.find('.select2-search__field').prop('placeholder', 'Search');

            dropdown.find('ul.select2-results__options').addClass('scrollbar');
            dropdown.offset({ left: parentPosition.left });
        }).on('select2:close', function() {
            if (inputGroupSelect.hasClass('horizontal')){
                inputGroupSelect.parent().toggleClass('focused');
                inputGroupSelect.removeClass('focused');
            }
        })

        inputGroupSelect.find('b[role="presentation"]').remove();
        if (!inputGroupSelect.hasClass('horizontal')){
            inputGroupSelect.find('span.select2-selection__arrow').append('<i class="fa fa-angle-down select-icon"></i>');
        } else {
            inputGroupSelect.find('span.select2-selection__arrow').remove();
        }
    });
});
