$('.staff').ready(function() {
    $('.staff').find('.actions').each(function (){
        let that = $(this);
        const binIcon = that.find('.bin-icon');

        binIcon.click(function (e) {
            e.stopPropagation();
            e.preventDefault();
            const that = $(this);
            const deleteModal = $('.ntn-modal--delete');
            deleteModal.parents('.ntn-modal').css({display: "flex"});
            deleteModal.find('.ntn-modal-header--title').text(that.data('modal-title'));
            deleteModal.find('.ntn-modal-body > b').text(that.data('modal-body'));
            deleteModal.find('#delete-btn').attr('data-href', this.href);
        });
    });

    const inviteLink = $('#invite-link');
    inviteLink.click(function (e) {
        e.preventDefault();
        $('.ntn-modal--invite-staff').parents('.ntn-modal').css({display: "flex"});
    });
});

$('.edit-company-container').ready(function () {
    const nameField = $('#company_name');
    const dealerCodeField = $('#company_dealer_code');
    const phoneField = $('#company_phone');
    const emailField = $('#company_email');
    const addressField = $('#company_address');
    const checkBox = $('#company_floorplan_swap');
    const blankNameField = $('#company_floorplan_blank_name');
    const cancelLink = $('.edit-company-container').find("a:contains('Cancel')");
    let obligatoryFields = [nameField, dealerCodeField, phoneField, emailField, addressField];
    if (checkBox.is(':checked')) {
        obligatoryFields.push(blankNameField);
    }

    handleObligatoryFields(obligatoryFields);
    handleToggles();
    handleCheckBox(checkBox, blankNameField, obligatoryFields);

    new Inputmask("(999) 999-9999").mask(phoneField);

    handleBackLink(cancelLink);
});

const handleCheckBox = function(checkBox, field, fields) {
    const inputGroup = field.parents('.input-group-large');

    checkBox.ready(function () {
        if (checkBox.is(':checked')) {
            inputGroup.show();
        }
    })

    checkBox.change(function() {
        if(this.checked) {
            inputGroup.show();

            let newFields = [...fields, field];
            toggleLinksClasses(newFields.every((field) => {return field.val().trim() !== ''} ));

            field.on('keyup', function (){
                const that = $(this);
                const errorContent = that.next('.error-content');
                const inputGroup = that.parents('.input-group-large');
                if (that.val().trim() === '') {
                    errorContent.text("Please, fill this field");
                    inputGroup.addClass('error');
                } else {
                    errorContent.text('');
                    inputGroup.removeClass('error');
                }
                toggleLinksClasses(newFields.every((field) => {return field.val().trim() !== ''} ));
            });
        } else {
            inputGroup.hide();
            toggleLinksClasses(fields.every((field) => {return field.val().trim() !== ''} ));
        }
    });
}

const handleObligatoryFields = function (fields) {
    $.each(fields, function (index, field) {
        field.on('keyup', function (){
            const that = $(this);
            const errorContent = that.next('.error-content');
            const inputGroup = that.parents('.input-group-large');
            if (that.val().trim() === '') {
                errorContent.text("Please, fill this field");
                inputGroup.addClass('error');
            } else {
                errorContent.text('');
                inputGroup.removeClass('error');
            }

            toggleLinksClasses(fields.every((field) => {return field.val().trim() !== ''} ))
        });
    });
}

const handleToggles = function () {
    $('.payment_methods').find('input').each(function () {
        $(this).on('change', function () {
            toggleLinksClasses(true);
        })
    });
}

const handleBackLink = function (link) {
    let isChanged = false;

    $('.edit-company-container').find(':input').each(function() {
        $(this).on('change', function () {
            isChanged = true;
        })
    });

    link.on('click', function (e) {
        e.preventDefault();

        if(isChanged) {
            $('.ntn-modal--unsaved-changes').parents('.ntn-modal').css({display: "flex"});
        } else {
            window.location.replace(document.referrer)
        }
    });
}

const toggleLinksClasses = function (success) {
    const saveBtn = $('.edit-company-container').find('.btn-large');
    if (success) {
        saveBtn.removeClass('disabled');
        saveBtn.removeAttr('disabled');
    } else {
        saveBtn.addClass('disabled');
        saveBtn.attr('disabled', 'true');
    }
}
