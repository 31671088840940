import $ from 'jquery';

$('.mapping-container').ready(function() {
    handleSelects();
    handleCancelLink();
    handleCloseIcon();
});

const handleSelects = function () {
    const selects = $('.mapping-container .form').find('select');

    selects.each(function() {
        let that = $(this);
        let inputGroupSmall = that.parents('.input-group-small');

        $('<div class="dropdown-parent"></div>').insertAfter(inputGroupSmall);
        let dropdownParent = that.parents('.input-group-small').next('.dropdown-parent');
        let prevSelection = '';

        that.select2({
            selectionCssClass: 'ntn-selection',
            dropdownCssClass: 'ntn-dropdown',
            width: "style",
            dropdownParent: dropdownParent
        }).on('select2:open', function() {
            let dropdown = dropdownParent.find('.ntn-dropdown');

            dropdownParent.find('.select2-search__field').prop('placeholder', 'Search');
            dropdown.find('ul.select2-results__options').addClass('scrollbar');
            inputGroupSmall.css({ 'border-bottom-right-radius': 0, 'border-bottom-left-radius': 0 });
            inputGroupSmall.find('span.select2-selection__arrow').find('i.ntn-icons.asc').hide();
            inputGroupSmall.find('span.select2-selection__arrow').find('i.ntn-icons.desc').show();

        }).on('select2:close', function() {
            inputGroupSmall.find('span.select2-selection__arrow').find('i.ntn-icons.asc').show();
            inputGroupSmall.find('span.select2-selection__arrow').find('i.ntn-icons.desc').hide();
            inputGroupSmall.removeAttr('style');
        }).on('select2:select', function () {
            let currentlySelected = that.find(':selected');
            let allExceptThat = selects.filter((i ,elem) => { return elem !== that.get(0) });
            if (currentlySelected.val() !== '') {
                if(prevSelection.val() !== '') {
                    allExceptThat.each(function (i, elem) {
                        let newOption = new Option(prevSelection.val(), prevSelection.val(), false, false);
                        $(elem).append(newOption).trigger('change');
                    });
                }

                allExceptThat.each(function (i, elem) {
                    $(elem).find(`option[value="${currentlySelected.val()}"]`).remove();
                });
            } else {
                allExceptThat.each(function (i, elem) {
                    let newOption = new Option(prevSelection.val(), prevSelection.val(), false, false);
                    $(elem).append(newOption).trigger('change');
                });
            }
        }).on('select2:selecting', function (){
            prevSelection = that.find(':selected');
        })

        inputGroupSmall.find('b[role="presentation"]').remove();
        inputGroupSmall.find('span.select2-selection__arrow').append('<i class="ntn-icons asc"></i>');
        inputGroupSmall.find('span.select2-selection__arrow').append('<i class="ntn-icons desc"></i>');
        inputGroupSmall.find('span.select2-selection__arrow').find('i.ntn-icons.desc').hide();
        if (that.find(':selected').val() === '') return;

        let selectedVal = that.find(':selected').val();
        let allExceptThat = selects.filter((i ,elem) => { return elem !== that.get(0) });
        allExceptThat.each(function (i, elem) {
            $(elem).find(`option[value="${selectedVal}"]`).remove();
        });
    });
}

const handleCancelLink = function () {
    $('.cancel-link').each(function (i, elem) {
        $(elem).click(function (e) {
            e.preventDefault();

            window.location.replace(document.referrer);
        })
    })
}

const handleCloseIcon = function () {
    if ($('.mapping-title-block').length > 0) {
        const icon = $('.ntn-icons.close');
        icon.click(function () {
            window.location.replace('/company');
        });
    }
}
