$(document).ready(function() {
    const modal = $(".ntn-modal");
    const closeSpan = modal.find('button.close');
    const cancelButton = modal.find('p.modal-cancel-link');
    const cancelDeleteModalBtn = modal.find('a.delete-cancel-link');
    const leaveButton = modal.find('#leave-btn');
    const inviteEmailField = modal.find('#user_emails');
    const inviteStaffBtn = modal.find('#invite-staff-btn');
    const deleteBtn = $('#delete-btn');

    closeSpan.on('click', function (e) {
        e.preventDefault();
        modal.each(function (index, modal){
            $(modal).removeAttr("style");
        })
    })

    cancelButton.on('click', function () {
        modal.each(function (index, modal){
            $(modal).removeAttr("style");
        });
    });

    cancelDeleteModalBtn.on('click', function (e) {
        e.preventDefault();
        modal.each(function (index, modal){
            $(modal).removeAttr("style");
        });
    });

    leaveButton.on('click', function (event) {
        event.preventDefault();
        window.location.replace(document.referrer)
    });

    $(window).on('click', function () {
        modal.each(function (index, modal){
            if (event.target === modal) {
                $(modal).removeAttr("style");
            }
        });
    });

    inviteEmailField.on('keyup', function () {
        const that = $(this);
        if (that.val().trim() !== '') {
            inviteStaffBtn.removeClass('disabled');
            inviteStaffBtn.removeAttr('disabled');
        } else {
            inviteStaffBtn.addClass('disabled');
            inviteStaffBtn.attr('disabled', 'true');
        }
    });

    deleteBtn.click(function (e) {
        e.preventDefault();
        const href = $(this).data('href');

        $.ajax(href, {
            type : 'DELETE',
            success: function () {
                location.reload();
            }, error: function (data) {
                console.log(data);
            }
        });
    })
});
