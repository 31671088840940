$(document).ready(function() {
    $(this).find('.number-input').each(function() {
        const input = $(this).find('input');
        const left_button = $(this).find('span.left-button');
        const right_button = $(this).find('span.right-button');

        left_button.click(function () {
            const value = parseInt(input.val());
            if (value > 1) {
                input.val(value - 1);
            }
        });
        right_button.click(function () {
            const value = parseInt(input.val());
            input.val(value + 1);
        });
    });
});