import $ from 'jquery';

$('.request-form').ready(function () {
    handleCancelButton();
    handleSelects();
});

const handleCancelButton = function () {
    const form = $('form');
    const link = form.find('.footer a');
    let isChanged = false;

    form.find(':input').each(function(i, el) {
        const input = $(el);
        input.on('change', function () {
            isChanged = true;
        })
    });

    link.click(function (e) {
        e.preventDefault();
        const modal = $('.ntn-modal--unsaved-changes').parent();
        modal.find('.modal-cancel-link').text('Stay on page');
        modal.find('.btn-default').text('Leave page');
        if(isChanged) {
            modal.css({display: "flex"});
        } else {
            window.location.replace(document.referrer);
        }
    });
}

const handleSelects = function () {
    const newAttrModal = $('.ntn-modal--vehicle-new-attribute').parent();

    $('.request-form').find('select').each(function() {
        let that = $(this);
        let customFormDefault = that.parents('.custom-form-default');

        $('<div class="dropdown-parent"></div>').insertAfter(customFormDefault);
        let dropdownParent = that.parents('.custom-form-default').next('.dropdown-parent');
        that.select2({
            selectionCssClass: 'ntn-selection',
            dropdownCssClass: 'ntn-dropdown',
            width: "resolve",
            dropdownParent: dropdownParent
        }).on('select2:open', function() {
            let parentPosition = customFormDefault.offset();
            let dropdown = dropdownParent.find('.ntn-dropdown');

            dropdownParent.find('.select2-search__field').prop('placeholder', 'Search');
            dropdown.find('ul.select2-results__options').addClass('scrollbar');
            if (customFormDefault.prev().length === 1) {
                customFormDefault.css({ 'border-radius': 0 });
            }
            dropdown.offset({ left: parentPosition.left });
            customFormDefault.find('span.select2-selection__arrow').find('i.ntn-icons.asc').hide();
            customFormDefault.find('span.select2-selection__arrow').find('i.ntn-icons.desc').show();

            let select2 = $(this).data('select2');
            if (select2.$results.parents('.select2-results').find('.add-new-link').length === 0) {
                let link = $(`<div class="add-new-link"><a href="#">+ Add new</a></div>`);
                select2.$results.parents('.select2-results').append(link);
            }
            select2.$results.parents('.select2-results').find('.add-new-link a').click(function (e){
                e.preventDefault();
                let selectName = that.data('name');
                newAttrModal.find('.ntn-modal-header--title').text(`Add new ${selectName}`);
                newAttrModal.find('.ntn-modal-body .description').text(
                    `if you made sure that the ${selectName} you’re looking for is not in the list, you can add it here:`
                );
                newAttrModal.find('.ntn-modal-body #new_attr').val('');
                newAttrModal.find('button.btn-default').attr({ 'data-select-id': that.attr('id') });
                newAttrModal.css({ display: 'flex' });
                that.select2('close');
            });

        }).on('select2:close', function() {
            customFormDefault.find('span.select2-selection__arrow').find('i.ntn-icons.asc').show();
            customFormDefault.find('span.select2-selection__arrow').find('i.ntn-icons.desc').hide();
            customFormDefault.removeAttr('style');
        });

        customFormDefault.find('b[role="presentation"]').remove();
        customFormDefault.find('span.select2-selection__arrow').append('<i class="ntn-icons asc"></i>');
        customFormDefault.find('span.select2-selection__arrow').append('<i class="ntn-icons desc"></i>');
        customFormDefault.find('span.select2-selection__arrow').find('i.ntn-icons.desc').hide();
    });

    let newAttrInput = newAttrModal.find('input');
    let newAttrBtn = newAttrModal.find('button.btn-default');

    newAttrInput.on('keyup', function () {
        if (newAttrInput.val() !== '') {
            newAttrBtn.removeAttr('disabled');
        } else {
            newAttrBtn.attr('disabled', true);
        }
    });

    newAttrBtn.click(function () {
        let newAttrVal = newAttrInput.val();
        let selectTag = $(`#${newAttrBtn.attr('data-select-id')}`);
        if (selectTag.find("option[value='" + newAttrVal + "']").length) {
            selectTag.val(newAttrVal).trigger('change');
        } else {
            let newOption = new Option(newAttrVal, newAttrVal, true, true);
            selectTag.append(newOption).trigger('change');
        }
        newAttrModal.removeAttr('style');
    });

    const makeSelect = $('#request_make');
    const modelSelect = $('#request_model');
    const trimSelect = $('#request_trim');
    const fields = [makeSelect, modelSelect, trimSelect];

    $(fields).each(function (i, field) {
        field.on('select2:select', function () {
            onChangeSelectsCallback(fields, field);
        });
    });
}

const onChangeSelectsCallback = function (fields, field) {
    let notEmptyFields = fields.filter((elem) => { return (elem.val() !== '' && elem.val() !== null) });
    let params = notEmptyFields.map((elem) => { return { field: elem.data('name'), value: elem.select2('data')[0].text } });
    $.ajax({
        type: "GET",
        url: 'fetch_dynamical_filters',
        dataType: 'json',
        contentType: 'application/json',
        data: {
            'dynamical_filters': JSON.stringify(params)
        }
    }).done(function (json) {
        $(fields).each(function (index, otherField) {
            let currentVal = otherField.val();
            otherField.empty();
            let otherFieldName = otherField.data('name');
            let emptyOption = new Option('', '', false, false);
            otherField.append(emptyOption);
            $(json[otherFieldName]).each(function (i, option) {
                let newOption = new Option(option, option, false, false);
                otherField.append(newOption);
            });
            if (!json[otherFieldName].includes(currentVal) && currentVal !== '') {
                let newOption = new Option(currentVal, currentVal, false, false);
                otherField.append(newOption);
            }
            otherField.val(currentVal).trigger('change');
        });
    });
}
