$(document).ready(function() {
    $(".dropdown-toggle").dropdown();

    $('body').on('click', function (e) {
        if ($('.dropdown-toggle').hasClass('show')) {
            if (!$(e.target).hasClass('dropdown-toggle')) {
                $(".dropdown-toggle").dropdown('toggle');
            }
        }
    });
});
