$('.ntn-modal--vehicle-package-prices').ready(function () {
    handleAddVehicleBtn();
    handlePackagePricesBackLink();
});

const handleAddVehicleBtn = function () {
    const currentModal = $('.ntn-modal--vehicle-package-prices').parent();
    const btn = currentModal.find('#next-btn');
    const packageModal = $('.ntn-modal--vehicle-package').parent();
    const requiredFields = [currentModal.find('#vehicle_sale_price'), currentModal.find('#vehicle_msrp'),
        currentModal.find('#vehicle_holdback'), currentModal.find('#vehicle_price')];
    const dealerAddsPrice = currentModal.find('#vehicle_dealer_adds_price');
    const form = currentModal.find('form');
    dealerAddsPrice.numeric({ allowMinus: false });
    $.each(requiredFields, function (i, field) {
        field.numeric({ allowMinus: false });

        field.on('keyup', function (){
            if (requiredFields.every((field) => { return field.val() !== ''; } )) {
                btn.removeAttr('disabled');
            } else {
                btn.attr('disabled', 'true');
            }
        });
    });

    btn.click(function () {
        let url = form.attr('action');
        $.ajax({
            type: "PATCH",
            url: url,
            data: form.serialize(),
            success: function(data) {
                currentModal.removeAttr("style");
                packageModal.find('.suggest-dropdown').find(`.item[data-id="${data.id}"]`).trigger('click');
                packageModal.css({ display: "flex" });
            },
            error: function (data){
                console.log(data);
            }
        });
    });
}

const handlePackagePricesBackLink = function () {
    const modal = $('.ntn-modal--vehicle-package-prices').parent();
    const link = modal.find('.back-link');
    const packageModal = $('.ntn-modal--vehicle-package').parent();
    link.click(function () {
        modal.removeAttr("style");
        packageModal.css({ display: "flex" });
    });
}
