$('.pagination').ready(function() {
    $('.pagination').find('span > a').each(function () {
        const that = $(this);
        that.parent().on('click', function (e){
            if($(e.target).prop("tagName") === 'SPAN') {
                window.location.href = that.attr('href');
            }
        })
    });
})
