$(document).ready(function () {
    window.deleteAlert = (element) => {
        element.parentElement.classList.add("alert--fade");
        setTimeout(() => element.parentElement.remove(), 250);
    }

    if (!$('.alert').hasClass('block')) {
        $('.alert').delay(10000).fadeOut();
    }
})

