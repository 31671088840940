$('.stepper').ready(function() {
    const stepper = $('.stepper');
    const stepperContent = $('.stepper-content');
    stepper.find('.inner').each(function () {
        $(this).on('click', function () {
            const that = $(this);
            if (that.hasClass('disabled')) { return; }

            const innerId = that.attr('id');
            const container = $(`#${innerId}-container`);
            if (!that.hasClass('active')) {
                stepper.removeActiveClassFromSteppers();
                container.showStepContainer(stepperContent);
                that.find('.step').addClass('active');
            }
        });
    });

    (function( $ ){
        $.fn.removeActiveClassFromSteppers = function() {
            this.find('.step').each(function () {
               $(this).removeClass('active')
            });
            return this;
        };
    })( jQuery );

    (function( $ ){
        $.fn.showStepContainer = function(stepperContent) {
            const thisContainer = $(this);
            stepperContent.find('.stepper-container').each(function () {
                const that = $(this);
                if(that.hasClass('show')) {
                    that.removeClass('show');
                }
            });
            thisContainer.addClass('show');
            return this;
        };
    })( jQuery );
});
