$('.ntn-modal--vehicle-prices').ready(function () {
    handleNextBtn();
});

const handleNextBtn = function () {
    const currentModal = $('.ntn-modal--vehicle-prices').parent();
    const btn = currentModal.find('#next-btn');
    const forTradeModal = $('.ntn-modal--vehicle-for-trade').parent();
    const packageModal = $('.ntn-modal--vehicle-package').parent();
    const requiredFields = [currentModal.find('#vehicle_sale_price'), currentModal.find('#vehicle_msrp'),
        currentModal.find('#vehicle_holdback'), currentModal.find('#vehicle_price')];
    const dealerAddsPrice = currentModal.find('#vehicle_dealer_adds_price');
    const form = currentModal.find('form');
    dealerAddsPrice.numeric({ allowMinus: false });
    $.each(requiredFields, function (i, field) {
        field.numeric({ allowMinus: false });

        field.on('keyup', function (){
            if (requiredFields.every((field) => { return field.val() !== ''; } )) {
                btn.removeAttr('disabled');
            } else {
                btn.attr('disabled', 'true');
            }
        });
    });

    btn.click(function () {
        let url = form.attr('action');
        const dataNextBtn = currentModal.data('next-btn');
        $.ajax({
            type: "PATCH",
            url: url,
            data: form.serialize(),
            success: function(data) {
                if(dataNextBtn === 'For trade') {
                    currentModal.removeAttr("style");
                    forTradeModal.css({ display: "flex" });
                    const stepHeading = forTradeModal.find('h5.step');
                    const backBtn = forTradeModal.find('p.back-link');
                    stepHeading.show();
                    backBtn.show();
                } else if (dataNextBtn === 'Package') {
                    currentModal.removeAttr("style");
                    packageModal.css({ display: "flex" });
                    const stepHeading = packageModal.find('h5.step');
                    const backBtn = packageModal.find('p.back-link');
                    stepHeading.show();
                    backBtn.show();
                }
            },
            error: function (data){
                console.log(data);
            }
        });
    });
}
