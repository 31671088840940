$('.requests-header').ready(function() {
    $('.requests-list').find(".item[data-link]").click(function () {
        window.location = $(this).data("link")
    });
});

$('.request-content').ready(function() {
    $('.request-content').find(".item[data-link]").click(function () {
        window.location = $(this).data("link")
    });
});