$('.search-section').ready(function () {
    let searchInput = $('#search_vin');
    if (searchInput.length === 0) { searchInput = $('#search_stock_number'); }
    if (searchInput.length === 0) { searchInput = $('#search_query'); }
    const searchInputContainer = $('.search-input-container');
    const inputGroup = searchInputContainer.find('.input-group-large');
    const dropDown = searchInputContainer.find('.suggest-dropdown');
    const closeIcon = searchInputContainer.find('.icon-right');
    const clearLink = $('#clear-link');
    const submitBtn = $('.help-btns').find('.btn-large');

    if ($('.search-section').length > 0) {
        if (searchInput.val().length >= 1) {
            clearLink.removeClass('disabled');
            submitBtn.removeAttr('disabled');
        }

        $('td#vin-td').each(function (i, el) {
            if ($(el).data('highlight') !== undefined) {
                let outputHtml = [
                    '<span class="highlight">',
                    $(el).data('highlight'),
                    '</span>',
                    $(el).data('usual')
                ].join('');
                $(el).html(outputHtml);
            }
        });

        $(window).click(function (e) {
            if (e.target !== searchInputContainer && searchInputContainer.has(e.target).length === 0) {
                dropDown.hide();
                inputGroup.removeClass('with-dropdown');
                closeIcon.hide();
            }
        });
    }

    $('table.vehicles').find("tr[data-link]").click(function () {
        window.location = $(this).data("link")
    })

    searchInput.on('keyup', function (e) {
        if (e.which === 38 || e.which === 40) {
            return;
        }

        if (searchInput.val().length >= 1) {
            closeIcon.show();
            clearLink.removeClass('disabled');
            submitBtn.removeAttr('disabled');
        } else {
            closeIcon.hide();
            clearLink.addClass('disabled');
            submitBtn.attr('disabled', true);
        }

        clearTimeout($(this).data('timer'));
        let search = this.value;
        let form = $(this).parents('form');
        let inventoryTab = $(this).data('inventory-tab');
        let previewCompany = $(this).data('preview-company');
        let packageSearch = $(this).data('package-search');
        if (search.length >= 1) {
            $(this).data('timer', setTimeout(function () {
                let url = ''
                if (inventoryTab) {
                    url = window.location.pathname + "/search_by_stock";
                } else if (previewCompany) {
                    url = window.location.pathname + "/search_by_vin";
                } else if (packageSearch) {
                    url = "search_by_query";
                } else {
                    url = "search_by_vin";
                }

                $.ajax({
                    type: "GET",
                    url: url,
                    data: form.serialize(),
                }).done(function (json) {
                    if (json.length > 0) {
                        dropDown.empty();
                        if (!packageSearch) {
                            $.each(json, function (index, el) {
                                let startIndex = el[0].toLowerCase().indexOf(search.toLowerCase());
                                let endIndex = startIndex + search.length;

                                let outputHtml = [
                                    el[0].slice(0, startIndex),
                                    '<span class="highlight">',
                                    el[0].slice(startIndex, endIndex),
                                    '</span>',
                                    el[0].slice(endIndex)
                                ].join('');

                                dropDown.append(`<div class="item" onclick="handleItemClick(this);" data-index=${index} data-id=${el[1]} tabindex=${index + 20}>${outputHtml}</div>`)
                            });
                        } else { // search by query
                            $.each(json, function (index, el) {
                                let source = el['_source'];
                                let highlight = el['highlight'];
                                let stockNumber = highlight?.['stock_number']?.[0] || source['stock_number'];
                                let vin = highlight?.['vin']?.[0] || source['vin'];
                                let year = source['year'];
                                let make = highlight?.['make']?.[0] || source['make'];
                                let model = highlight?.['model']?.[0] || source['model'];
                                let trim = source['trim'];
                                let item =
                                    `<div class="item column" onclick="handleItemClick(this);" data-index=${index} data-id=${source['id']} tabindex=${index + 20}>` +
                                        `<div class="top">` +
                                            `<div class="stock-number">#${stockNumber}</div>` +
                                            `<div class="vin">${vin}</div>` +
                                        `</div>` +
                                        `<div class="bottom">${year} ${make} ${model} ${trim}</div>` +
                                    `</div>`;
                                dropDown.append(item);
                            });
                        }
                        dropDown.show();
                        inputGroup.addClass('with-dropdown');
                    } else {
                        dropDown.hide();
                        inputGroup.removeClass('with-dropdown');
                    }
                });
            }, 500));
        } else {
            dropDown.empty();
            dropDown.hide();
            inputGroup.removeClass('with-dropdown');
        }
    });

    searchInputContainer.on('keydown', function (e) {
        if (e.which === 13) {
            submitBtn.trigger('click');
        }
        if (dropDown.children().length === 0) return;

        let currentIndex = searchInputContainer.data('current-index');
        let itemsCount = dropDown.children().length;

        switch (e.which) {
            case 38: // up
                if (currentIndex === 0 || currentIndex === -1) {
                    currentIndex = itemsCount - 1;
                } else {
                    currentIndex -= 1;
                }
                searchInputContainer.data('current-index', currentIndex);
                dropDown.find(`.item[data-index="${currentIndex}"]`).focus();
                break;

            case 40: // down
                if (currentIndex === itemsCount - 1 || currentIndex === -1) {
                    currentIndex = 0;
                } else {
                    currentIndex += 1;
                }
                searchInputContainer.data('current-index', currentIndex);
                dropDown.find(`.item[data-index="${currentIndex}"]`).focus();
                break;

            default:
                return; // exit this handler for other keys
        }

        dropDown.find('.item:focus').each(function () {
            const that = $(this);
            that.on('keydown', function (e) {
                if (e.which === 13) {
                    that.trigger('click');
                }
                e.preventDefault(); // prevent the default action (scroll / move caret)
            });
        });

        e.preventDefault(); // prevent the default action (scroll / move caret)
    });

    window.handleItemClick = (element) => {
        searchInput.val(element.innerText);
        dropDown.hide();
        inputGroup.removeClass('with-dropdown');

        submitBtn.trigger('click');
    }

    searchInput.focusin(function () {
        if (dropDown.children().length > 0) {
            dropDown.show();
            inputGroup.addClass('with-dropdown');
            searchInput.trigger('keyup');
            searchInputContainer.data('current-index', -1);
        } else {
            searchInput.trigger('keyup');
        }

        if (searchInput.val().length >= 1) {
            closeIcon.show();
        } else {
            closeIcon.hide();
        }
    });

    closeIcon.on('click', function () {
        searchInput.val('');
        searchInput.trigger('keyup');
        searchInput.focus();
    });

    $('.status-filter').ready(function () {
        const statusFilter = $('.status-filter');
        const filterTitle = $('.filter-title');
        const statusFilterDropdown = statusFilter.find('.dropdown');

        filterTitle.on('click', function () {
            statusFilterDropdown.toggle();
        });

        $(window).click(function (e) {
            if (e.target !== statusFilter && statusFilter.has(e.target).length === 0) {
                statusFilterDropdown.hide();
            }
        });

        clearLink.click(function () {
            if(clearLink.hasClass('disabled')) return;

            if (clearLink.parents('.tabs-content').length === 0) {
                window.location = '/global_vehicles/index_vin';
            } else {
                window.location = window.location.href.split("?")[0] + '?inventory_tab=true';
            }
        });
    });
});
