$('.change-password-content').ready(function() {
    const cancelLink = $('.action-item > a');
    let isChanged = false;

    $('.change-password-content').find('input').each(function (){
        const input = $(this)
        input.on('change', function () {
            isChanged = true;
        });
    });

    cancelLink.on('click', function () {
        event.preventDefault();

        if(isChanged) {
            $('.ntn-modal--unsaved-changes').parents('.ntn-modal').css({display: "flex"});
        } else {
            window.location.replace(document.referrer)
        }
    })
})
