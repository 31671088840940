$('.ntn-modal--change-from-for-trade-status').ready(function () {
    handleOkBtn();
})

const handleOkBtn = function () {
    const currentModal = $('.ntn-modal--change-from-for-trade-status').parent();
    const packageModal = $('.ntn-modal--vehicle-package').parent();
    const btn = $('.ntn-modal--change-from-for-trade-status').find('#ok-btn');
    const form = $('.ntn-modal--change-from-for-trade-status').find('form').first();

    const inStockForm = currentModal.find('#in-stock-form');
    btn.click(function () {
        const statusTo = currentModal.data('status-to');
        let url = form.attr('action');

        $.ajax({
            type: "PATCH",
            url: url,
            data: form.serialize(),
            success: function(data) {
                if (statusTo === 'in_stock') {
                    $.ajax({
                        type: "PATCH",
                        url: inStockForm.attr('action'),
                        data: inStockForm.serialize(),
                        success: function(data) {
                            location.reload();
                        },
                        error: function (data){
                            console.log(data);
                        }
                    });
                }

                currentModal.removeAttr("style");
                if (statusTo === 'package') {
                    packageModal.css({display: 'flex'});
                }
            },
            error: function (data){
                console.log(data);
            }
        });
    });
}
