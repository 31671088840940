$('#sign-up').ready(function() {
    initStepper();
    validatePasswordFields();
    validateProfileForm();
    validateCompanyForm();
});

const initStepper = function () {
    $('#first-step-container').addClass('show');
    $('#first-step').find('.step').addClass('active');
    $('#second-step').addClass('disabled');
    const thirdStep = $('#third-step')
    thirdStep.addClass('disabled');
    if ($('.stepper').data('steps') === 2) {
        thirdStep.remove();
        const thirdStepContainer = $('#third-step-container');
        const submitBtn = thirdStepContainer.find('.btn-large');
        thirdStepContainer.remove();
        const secondStepContainer = $('#second-step-container');
        secondStepContainer.find('a.btn-large').remove();
        secondStepContainer.find('.actions').prepend(submitBtn);
    }
}

const validatePasswordFields = function (){
    const firstStepContainer = $('#first-step-container');
    const passwordField = firstStepContainer.find('#_user_password');
    const repeatPasswordField = firstStepContainer.find('#_user_password_confirmation');
    const passInputGroup = passwordField.parents('.input-group-large');
    const repeatInputGroup = repeatPasswordField.parents('.input-group-large');

    passwordField.on('keyup', function () {
        const that = $(this);
        const errorContent = that.next('.error-content');
        const inputGroup = that.parents('.input-group-large');
        const successIcon = inputGroup.find('.icon-right img').parent();
        const eyeIcon = inputGroup.find('.icon-right i').parent();
        if (that.val().length < 8) {
            errorContent.text('It is too short, should be 8 characters or more');
            inputGroup.addClass('error');
            inputGroup.removeClass('success');
            successIcon.hide();
            eyeIcon.show();
        } else {
            errorContent.text('');
            inputGroup.removeClass('error');
            inputGroup.addClass('success');
            eyeIcon.hide();
            successIcon.removeAttr('style').delay(5000).fadeOut(function () { eyeIcon.fadeIn(); });
        }
        if (repeatPasswordField.val().length > 0)
            repeatPasswordField.trigger('keyup');
        toggleLinksClasses((repeatInputGroup.hasClass('success') && passInputGroup.hasClass('success')), $('#first-step'));
    });

    repeatPasswordField.on('keyup', function () {
        const that = $(this);
        const errorContent = that.next('.error-content');
        const inputGroup = that.parents('.input-group-large');
        const successIcon = inputGroup.find('.icon-right img').parent();
        const eyeIcon = inputGroup.find('.icon-right i').parent();
        if (that.val() !== passwordField.val()) {
            errorContent.text("Doesn't match");
            inputGroup.addClass('error');
            inputGroup.removeClass('success');
            successIcon.hide();
            eyeIcon.show();
        } else {
            errorContent.text('');
            inputGroup.removeClass('error');
            if (passwordField.parents('.input-group-large').hasClass('success')) {
                inputGroup.addClass('success');
                successIcon.removeAttr('style');
                eyeIcon.hide();
                successIcon.removeAttr('style').delay(5000).fadeOut(function () { eyeIcon.fadeIn(); });
            }
        }
        toggleLinksClasses(repeatInputGroup.hasClass('success') && passInputGroup.hasClass('success'), $('#first-step'));
    });

    handleNextBtn($('#first-step'));
}

const validateProfileForm = function () {
    const step = $('#second-step');

    const firstNameInput = $('#_profile_first_name');
    const lastNameInput = $('#_profile_last_name');
    const titleInput = $('#_profile_title');
    const phoneInput = $('#_profile_phone');
    const obligatoryFields = [firstNameInput, lastNameInput, titleInput, phoneInput];

    new Inputmask("(999) 999-9999").mask(phoneInput);
    firstNameInput.alpha();
    lastNameInput.alpha();
    titleInput.alphanum();

    handleObligatoryFields(obligatoryFields, step)

    handleBackLink(step);
    if (step.next().length > 0) {
        handleNextBtn(step);
    }
}

const validateCompanyForm = function () {
    const step = $('#third-step');

    const nameField = $('#_company_name');
    const dealerCodeField = $('#_company_dealer_code');
    const phoneField = $('#_company_phone');
    const emailField = $('#_company_email');
    const addressField = $('#_company_address');
    const checkBox = $('#_company_floorplan_swap');
    const blankNameField = $('#_company_floorplan_blank_name');
    const obligatoryFields = [nameField, dealerCodeField, phoneField, emailField, addressField];
    const checkBoxes = [$('#_company_check'), $('#_company_wire_transfer'), checkBox]

    handleObligatoryFields(obligatoryFields, step, checkBoxes);
    handleCheckBox(checkBox, blankNameField, step, obligatoryFields);

    new Inputmask("(999) 999-9999").mask(phoneField);
    nameField.alphanum();
    dealerCodeField.alphanum();
    addressField.alphanum();
    blankNameField.alphanum();

    handleBackLink(step);
}

const handleCheckBox = function(checkBox, field, step, fields) {
    const inputGroup = field.parents('.input-group-large');
    checkBox.change(function() {
        if(this.checked) {
            inputGroup.show();

            let newFields = [...fields, field];
            toggleLinksClasses(newFields.every((field) => {return field.val().trim() !== ''} ), step);

            field.on('keyup', function (){
                const that = $(this);
                const errorContent = that.next('.error-content');
                const inputGroup = that.parents('.input-group-large');
                if (that.val().trim() === '') {
                    errorContent.text("Please, fill this field");
                    inputGroup.addClass('error');
                } else {
                    errorContent.text('');
                    inputGroup.removeClass('error');
                }
                toggleLinksClasses(newFields.every((field) => {return field.val().trim() !== ''} ), step);
            });
        } else {
            inputGroup.hide();
            toggleLinksClasses(fields.every((field) => {return field.val().trim() !== ''} ), step);
        }
    });
}

const handleObligatoryFields = function (fields, step, checkBoxes=null) {
    $.each(fields, function (index, field) {
        field.on('keyup', function (){
            const that = $(this);
            const errorContent = that.next('.error-content');
            const inputGroup = that.parents('.input-group-large');
            if (that.val().trim() === '') {
                errorContent.text("Please, fill this field");
                inputGroup.addClass('error');
            } else {
                errorContent.text('');
                inputGroup.removeClass('error');
            }
            if (checkBoxes === null) {
                toggleLinksClasses(fields.every((field) => { return field.val().trim() !== '' }), step)
            } else {
                toggleLinksClasses(
                    fields.every((field) => { return field.val().trim() !== ''} )
                    && checkBoxes.some((checkBox) => { return checkBox.prop("checked") === true }),
                    step
                )
            }
        });
    });
    if (checkBoxes !== null) {
        $.each(checkBoxes, function (index, checkBox) {
            checkBox.change(function() {
                toggleLinksClasses(
                    fields.every((field) => { return field.val().trim() !== ''} )
                    && checkBoxes.some((checkBox) => { return checkBox.prop("checked") === true }),
                    step
                )
            })
        })
    }
}

const handleBackLink = function (step) {
    const link = $(`#${step.attr('id')}-container`).find('a:contains(\'Back\')');
    link.on('click', function (e) {
       e.preventDefault();
       step.prev().click();
    });
}

const handleNextBtn = function (step) {
    const btn = $(`#${step.attr('id')}-container`).find('.btn-large');
    btn.on('click', function (e) {
        e.preventDefault();
        step.next().click();
    });
}

const toggleLinksClasses = function (success, step) {
    const stepContainer = $(`#${step.attr('id')}-container`);
    const nextBtn = stepContainer.find('.btn-large');
    if (success) {
        step.next().removeClass('disabled');
        step.next().find('.line:last-of-type').addClass('disabled');
        step.find('.line:last-of-type').removeClass('disabled');
        step.addClass('finished');
        nextBtn.removeClass('disabled');
        nextBtn.removeAttr('disabled');
        handleTooltip(nextBtn);
    } else {
        step.next().addClass('disabled');
        step.next().find('.line:last-of-type').removeClass('disabled');
        step.find('.line:last-of-type').addClass('disabled');
        step.removeClass('finished');
        nextBtn.addClass('disabled');
        nextBtn.attr('disabled', 'true');
        handleTooltip(nextBtn);
    }
}

const handleTooltip = function (nextBtn) {
    if (nextBtn.data('tooltip')) {
        if (nextBtn.attr('disabled')) {
            nextBtn.parents('.tooltip-container').attr('role', 'tooltip')
        } else {
            nextBtn.parents('.tooltip-container').removeAttr('role')
        }
    }
}
