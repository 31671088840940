$('.profile-form').ready(function() {
    new Inputmask("(999) 999-9999").mask($('#user_phone'));
    let isChanged = false;

    $('.profile-form').find('.input-group-large').each(function() {
        const input = $(this).find('input');
        input.on('change', function () {
            isChanged = true;
        })
    })

    $('#cancel').on('click', function () {
        event.preventDefault();

        if(isChanged) {
            $('.ntn-modal--unsaved-changes').parents('.ntn-modal').css({display: "flex"});
        } else {
            window.location.replace(document.referrer);
        }
    })
});
