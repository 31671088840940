import consumer from "./consumer"

consumer.subscriptions.create("NotificationsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    const notificationsContainer = $('.notifications-container');
    const navLink = $('.notifications').find('a.nav-link');
    let notification = data.notification
    let iconClass = notification.notifiable === 'Request' ? 'ntn-icons approve' : 'ntn-icons taxi';
    let item = `
                        <a class="notification" href="/notifications/${notification.id}">
                          <div class="icon">
                            <i class="${iconClass}"></i>
                          </div>
                          <div class="body">
                            <div class="title">${notification.title}</div>
                            <div class="description">${notification.description}</div>
                          </div>
                       </a>
                    `;
    notificationsContainer.append(item);
    if (data.unread_count > 0) {
      let counter = `<span class="counter">${data.unread_count}</span>`;
      navLink.append(counter);
      let actualCounter = navLink.find('span.counter');
      actualCounter.css({height: actualCounter.width() + 'px'});
    }
  }
});
