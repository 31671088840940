$('.ntn-modal--change-from-package-status').ready(function () {
    handleApplyBtn();
})

const handleApplyBtn = function () {
    const currentModal = $('.ntn-modal--change-from-package-status').parent();
    const forTradeModal = $('.ntn-modal--vehicle-for-trade').parent();
    const btn = currentModal.find('#apply-btn');
    const form = currentModal.find('form');
    const radioButtons = form.find('input[type=radio]');
    radioButtons.each(function (i, radio) {
        $(radio).on('change', function () {
            if (radioButtons.toArray().some((el) => { return $(el).is(':checked') })) {
                btn.removeAttr('disabled');
            } else {
                btn.attr({ disabled: true });
            }
        });
    });

    btn.click(function () {
        let statusTo = currentModal.attr('data-status-to');
        let url = form.attr('action');
        let data = form.serializeArray();
        $.ajax({
            type: "PATCH",
            url: url,
            data: data,
            success: function(data) {
                currentModal.removeAttr("style");
                if (statusTo === 'for_trade') {
                    forTradeModal.css({display: 'flex'});
                } else {
                    location.reload();
                }
            },
            error: function (data){
                console.log(data);
            }
        });
    });
}
