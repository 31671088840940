$('.temp-vehicles-page').ready(function() {
    $('.temp-vehicles-page').find('table').floatingScrollbar();
    handleAlertBlock();
    handleCloseIcon();
})

const handleAlertBlock = function () {
    const alert = $('.alert-container.block');
    const alertTitle = alert.find('.title');
    let invalidVehiclesCount = alertTitle.data('invalid-vehicles-count');
    let invalidFieldsCounter = alertTitle.data('invalid-fields-counter');
    changeAlertText(invalidFieldsCounter, invalidVehiclesCount, alertTitle);

    let inputs = $('.temp-vehicles-page').find('table').find('input[type=text]');
    let trs = $('.temp-vehicles-page').find('table').find('tr');

    inputs.each(function (i, input){
        $(input).on('keyup', function (){
            if ($(input).val() !== '') {
                $(input).attr('data-invalid', 'false');
                $(input).removeClass('error').addClass('success');
                if ($(input).parents('tr').find('input').toArray().every((field) => {return $(field).attr('data-invalid') === 'false'} )) {
                    $(input).parents('tr').attr('data-invalid', 'false');
                    $(input).parents('tr').removeClass('invalid').addClass('valid');
                }
            } else {
                $(input).attr('data-invalid', 'true');
                $(input).removeClass('success').addClass('error');
                $(input).parents('tr').attr('data-invalid', 'true');
                $(input).parents('tr').removeClass('valid').addClass('invalid');
            }

            invalidFieldsCounter = 0;
            invalidVehiclesCount = 0;
            inputs.each(function (i, field) {
                if ($(field).attr('data-invalid') === 'true') { invalidFieldsCounter++; }
            });
            trs.each(function (i, tr) {
                if ($(tr).attr('data-invalid') === 'true') { invalidVehiclesCount++; }
            });

            if (invalidVehiclesCount === 0) {
                return changeAlertToSuccess(alert);
            } else {
                changeAlertToAlert(alert);
            }
            changeAlertText(invalidFieldsCounter, invalidVehiclesCount, alertTitle);
        });
    });
}

const changeAlertText = function (invalidFieldsCounter, invalidVehiclesCount, alertTitle) {
    alertTitle.text(`${invalidFieldsCounter} required fields in ${invalidVehiclesCount} vehicles are blank!`);
}

const changeAlertToSuccess = function (alert) {
    alert.find('.alert').removeClass('alert--alert');
    alert.find('.alert').addClass('alert--notice');
    alert.find('.title').text('All done!');
    alert.find('.description').text('The inventory is ready.');
    alert.find('.alert__button').show();
}

const changeAlertToAlert = function (alert) {
    alert.find('.alert').removeClass('alert--notice');
    alert.find('.alert').addClass('alert--alert');
    alert.find('.title').text('');
    alert.find('.description').text('Fill them in to publish the inventory.');
    alert.find('.alert__button').hide();
}

const handleCloseIcon = function () {
    if ($('.temp-vehicles-page').length > 0) {
        const icon = $('.ntn-icons.close');
        icon.click(function () {
            window.location.replace('/company');
        });
    }
}
